.home-try-header {
  @include respond-property('margin-bottom', 154px, 144px, 90px, 72px, 108px, 72px);
  padding-bottom: 10px;
  overflow: hidden;

  .h1 {
    text-align: center;
  }

  .lead {
    margin-bottom: 1em;
    text-align: center;
  }

  .try-images {
    @include respond-property('padding-top', 130px, 100px, 60px, 58px, 58px, 58px);
    @include respond-property('margin-bottom', 64px, 64px, 42px, 38px, 38px, 38px);
    overflow: hidden;

    @include respond-down(S){
      margin-right: -200px;
      margin-left: -200px;
    }

    .try-images-slider {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      max-width: 1400px;
      margin: auto;

      .owl-stage {
        display: flex;
        align-items: flex-end;
      }

      .owl-item {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        opacity: 1;

        @include respond-down(S){
          width: 166px;
        }

        &.center {
          @include respond-down(M) {
            .item {
              transform: scale(1);
            }
          }
        }

      }

      @include respond-down(XL){
        max-width: 1150px;
      }
      @include respond-down(L){
        max-width: 900px;
      }
      @include respond-down(M){
        max-width: 84%;
      }
      @include respond-down(S){
        max-width: unset;
        width: 650px;
        margin: auto;
      }
      @include respond-down(XS){
        width: 600px;
      }

      .item {
        cursor: pointer;
        position: relative;
        transform: scale(0.96);
        transition: transform 0.85s ease;
        width: 290px;
        display: flex;
        justify-content: center;

        @include respond-down(L){
          width: 220px;
        }
        @include respond-down(M){
          width: 180px;
        }
        @include respond-down(S){
          width: 160px;
        }
        @include respond-down(XS){
          width: 150px;
        }

        img {
          position: relative;
          display: block;
          z-index: 10;

          @include respond-down(S){
            max-height: 200px;
          }
        }

        .spot {
          width: 90%;
          display: block;
          position: absolute;
          transition: transform 0.35s ease, opacity 0.25s ease;
          transform: scale(0.8);
          opacity: 0;
          left: 70px;
          top: -10px;
        }

        &.party {
          @include respond-down(S){
            margin-right: 2%;
          }

          path {
            fill: #CCC37D;
          }
        }

        &.romance {
          margin-left: 7.5%;

          @include respond-down(XL){
            margin-left: 10%;
          }
          @include respond-down(L){
            margin-left: 9.8%;
          }
          @include respond-down(M){
            margin-left: 12%;
          }
        }

        &.solo {
          @include respond-down(S){
            margin-left: 8%;
          }

          path {
            fill: #CB5F90;
          }

          .spot {
            transform: rotate(-100deg);
            left: -50px;
            top: -50px;
          }
        }

        @include respond-up(L){
          &:hover {
            transform: scale(1);

            .spot {
              transition: transform 0.25s ease, opacity 0.55s ease;
            }

            &.party {
              .spot {
                transform: translate(48%, -25%) scale(1);
                opacity: 1;
              }
            }

            &.romance {
              .spot {
                transform: translate(-6%, -52%) scale(1);
                opacity: 1;
              }
            }

            &.solo {
              .spot {
                transform: translate(-46%, -13%) rotate(-100deg) scale(1);
                opacity: 1;
              }
            }
          }
        }

        @include respond-down(M) {
          // transform: scale(1);

          .spot {
            width: 80%;
            opacity: 1;
          }

          &.party .spot {
            transform: translate(36%, -18%) scale(1);
          }

          &.romance .spot {
            transform: translate(-12%, -43%) scale(1);
          }

          &.solo .spot {
            transform: translate(-22%, -3%) rotate(-100deg) scale(1);
          }
        }
      }
    }
  }

  .big-button {
    .text {
      justify-content: center;

      svg {
        margin-left: 0.4em;
        width: 98px;

        @include respond-down(L){
          width: 70px;
        }
      }
    }
  }
}
