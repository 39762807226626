.home-subscribe {
  position: relative;
  overflow: hidden;

  .cloud {
    position: absolute;
    top: 62px;
    left: 50%;
    animation: left-right 29s linear infinite;
    width: 132px;

    &:nth-child(1) {
      @include respond-down(L){
        width: 104px;
        top: 50px;
      }
      @include respond-down(M){
        top: 32px;
        animation: left-right 17s linear infinite;
      }
    }

    &:nth-child(2) {
      animation: left-right 47s linear infinite;
      animation-delay: -20s;
      top: 115px;
      width: 66px;

      @include respond-down(L){
        width: 52px;
        top: 70px;
      }
      @include respond-down(M){
        top: 392px;
        animation: left-right 27s linear infinite;
      }
      @include respond-down(S){
        top: 370px;
      }
    }
  }

  @keyframes left-right {
    0% { transform: translateX(-60vw); opacity: 0; }
    5% { opacity: 1; }
    95% { opacity: 1; }
    100% { transform: translateX(60vw); opacity: 0; }
  }

  .mini-container {
    padding-top: 170px;
    display: flex;
    justify-content: space-between;

    @include respond-down(L){
      padding-top: 130px;
    }
    @include respond-down(M){
      padding-top: 60px;
      flex-direction: column;
      align-items: center;
    }
    @include respond-down(S){
      padding-top: 150px;
    }

    .subscribe-title {
      text-transform: uppercase;
      font-size: 42px;
      font-weight: 400;
      margin-bottom: 170px;
      line-height: 1.45em;

      @include respond-down(L){
        font-size: 31px;
        margin-bottom: 120px;
      }
      @include respond-down(M){
        font-size: 28px;
        text-align: center;
        margin-bottom: 40px;
      }
      @include respond-down(S){
        font-size: 25px;
      }
    }

    .fly {
      position: absolute;
      left: -150px;
      top: 194px;

      @include respond-down(L){
        width: 346px;
        left: -80px;
        top: 128px;
      }
      @include respond-down(M){
        display: none;
      }
    }

    .subscribe-form {
      flex: 0 0 50%;
      padding-left: 140px;
      position: relative;

      @include respond-down(L){
        padding-left: 90px;
      }
      @include respond-down(M){
        padding-left: 0;
      }

      .mails {
        position: absolute;
        left: 16px;
        top: 20px;

        @include respond-down(L){
          width: 60px;
          left: 0;
        }
        @include respond-down(M){
          left: auto;
          top: -20px;
          width: 94px;
          right: -160px;
        }
        @include respond-down(S){
          top: -220px;
          width: 80px;
          right: 50%;
          margin-right: -40px;
        }
      }

      .form {
        position: relative;
        z-index: 20;
        width: 285px;

        @include respond-down(L){
          width: 224px;
        }
        @include respond-down(M){
          width: 274px;
        }

        .email-input-wrapper {
          margin-bottom: 22px;

          @include respond-down(M){
            margin-bottom: 28px;
          }
        }

        .big-button {
          margin-bottom: 40px;
          width: 100%;

          @include respond-down(M){
            margin-bottom: 48px;
            width: 86%;
          }
          @include respond-down(S){
            max-width: 196px;
          }
        }

        .confirm {
          color: #8D8888;
          font-family: Tinos, serif;
          line-height: 1.45em;
          font-size: 12px;
          margin-right: -70px;
          margin-bottom: 60px;

          @include respond-down(M){
            margin-bottom: 120px;
          }
          @include respond-down(S){
            padding-left: 28%;
            margin-right: 0;
          }

          a {
            color: #8D8888;
            text-decoration: underline;

            @include respond-up(L){
              &:hover {
                color: #232323;
              }
            }
          }
        }
      }

      .postbox {
        position: absolute;
        right: -170px;
        bottom: -10px;
        width: 185px;

        @include respond-down(L){
          width: 124px;
          right: -80px;
          bottom: -20px;
        }
        @include respond-down(M){
          width: 140px;
          right: auto;
          left: -180px;
          transform: scaleX(-1);
        }
        @include respond-down(S){
          width: 110px;
          left: -70px;
          bottom: -40px;
        }

        .eye {
          position: absolute;
          top: 65px;
          left: 20px;
          width: 48px;
          display: flex;
          justify-content: space-between;
          transition: opacity 0.15s ease, transform 0.15s ease;
          opacity: 1;

          @include respond-down(L){
            top: 44px;
            left: 11px;
            width: 41px;
          }
          @include respond-down(M){
            left: 14px;
          }
          @include respond-down(S){
            top: 36px;
            left: 10px;
            width: 35px;
          }

          .one {
            width: 20px;
            height: 20px;
            position: relative;
            overflow: hidden;

            @include respond-down(L){
              width: 18px;
              height: 18px;
            }
            @include respond-down(S){
              width: 16px;
              height: 16px;
            }

            &:after {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              border-radius: 40px;
              background: #FFF;
              transition: transform 0.03s ease;
            }

            i {
              display: block;
              width: 12px;
              height: 12px;
              border-radius: 40px;
              position: absolute;
              left: 4px;
              top: 4px;
              background: #000;
              z-index: 10;

              @include respond-down(L){
                left: 3px;
                top: 3px;
              }
              @include respond-down(S){
                width: 10px;
                height: 10px;
              }

              &:after {
                position: absolute;
                right: 3px;
                top: 3px;
                background: #FFF;
                width: 3px;
                height: 3px;
                border-radius: 5px;
                content: '';
              }
            }
          }

          &.hide {
            opacity: 0;
            transform: translateX(10px);
          }

          &.mig {
            .one:after {
              transform: scale(1, 0);
            }
          }
        }

        .img {
          width: 100%;
        }
      }
    }
  }
}

