.wines-list-block {
  position: relative;

  &.linear {
    .bottle-cart-wrapper {
      @include respond-down(S){
        flex: 0 0 100%;
      }
    }
  }

  .wines-list {
    position: relative;
    overflow: hidden;
    min-height: 300px;

    .container {
      position: relative;
      z-index: 100;
    }

    &.theme-yellow {
      @include respond-down(S){
        padding-top: 130px;
        padding-bottom: 70px;
      }
    }
  }
}
