@import "settings";
@import "_common/grid";

/* ------------------------------------------------------------------------- */

@import "_parts/home-try-header";
@import "_parts/home-subscribe";
@import "_parts/wines-list";
@import "_parts/owl";

/* ------------------------------------------------------------------------- */



.wines-list-block {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
  }

  .fresh-decorator {
    position: absolute;

    @include respond-down(M){
      display: none;
    }

    &.ruka {
      top: 24%;
      left: -140px;

      @include respond-down(L){
        width: 200px;
        left: -100px;
      }
    }

    &.noga {
      top: 35%;
      right: -80px;

      @include respond-down(L){
        width: 120px;
        right: -60px;
      }
    }
  }

  .wines-header {
    width: 200px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    left: 50%;
    margin-left: -100px;
    top: -50px;

    img {
      position: relative;
      z-index: 50;
    }

    .spot {
      position: absolute;
      z-index: 20;
      display: block;
    }

    &.fresh-header {
      img {
        width: 165px;

        @include respond-down(L) {
          width: 118px;
        }
      }

      .item-1 {
        top: -121px;
        left: -28px;
        width: 130%;

        @include respond-down(L) {
          top: -60px;
          left: 10px;
          width: 100%;
        }
      }
      .item-2 {
        top: -14px;
        left: 78px;
        width: 90%;
        transform: rotate(14deg);

        @include respond-down(L) {
          top: 14px;
          left: 98px;
          width: 70%;
        }
      }
    }

    &.month-header {
      img {
        width: 140px;

        @include respond-down(L) {
          width: 102px;
        }
      }

      .item-1 {
        top: 0;
        left: -100px;
        width: 110%;
        transform: rotate(210deg);

        @include respond-down(L) {
          left: -50px;
          width: 80%;
        }
      }
      .item-2 {
        top: -70px;
        left: 0px;
        width: 90%;
        transform: rotate(230deg);

        @include respond-down(L) {
          top: -40px;
          left: 30px;
          width: 70%;
        }
      }
    }

    &.sadness-header {
      img {
        width: 186px;

        @include respond-down(L) {
          width: 150px;
        }
      }

      .item-1 {
        top: -90px;
        left: -45px;
        width: 120%;
        transform: rotate(-24deg);

        @include respond-down(L) {
          top: -60px;
          left: -5px;
          width: 90%;
        }
      }
    }
  }

  .wines-list {
    background: rgba(232, 30, 53, 0.07);
    padding-top: 150px;
    padding-bottom: 40px;

    @include respond-down(XL){
      padding-top: 100px;
    }
    @include respond-down(M){
      padding-bottom: 20px;
    }

    &:after, &:before {
      content: '';
      position: absolute;
      height: 3px;
      background: #FFF;
      border-radius: 3px;
      width: 100%;
      top: 12px;
    }
    &:before {
      left: 50%;
      margin-left: 130px;

      @include respond-down(L){
        margin-left: 100px;
      }
    }
    &:after {
      right: 50%;
      margin-right: 130px;

      @include respond-down(L){
        margin-right: 100px;
      }
    }
  }

  .container {
    -ms-scroll-snap-type: proximity;
    scroll-snap-type: proximity;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      display: none;
    }

    @include respond-down(M){
      margin: 0 0 30px 0;
      padding: 0 4%;
      overflow: auto;
      -ms-scroll-snap-type: proximity;
      scroll-snap-type: proximity;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
      z-index: 200;
    }

    .bottle-items-wrapper {
      @include respond-down(M){
        flex-wrap: nowrap;
      }
      @include respond-down(S){
        margin-left: 25%;
        margin-right: 25%;
      }
      @include respond-down(XS){
        margin-left: 20%;
        margin-right: 20%;
      }
    }
  }

  .bg-helper {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 50;

    .blob {
      position: absolute;
      z-index: 20;
    }
  }

  &.dlja-grusti-i-toski {
    .blob {
      position: absolute;
      z-index: 20;
    }
  }

  &.group-vesenniy-veter {
    .bg-helper {
      background: url('/static/images/header/vesenniy-veter.bg.svg') no-repeat center center;
      background-size: 100% auto;

      @include respond-down(L){
        background-size: 108% auto;
      }
      @include respond-down(M){
        background-size: 120% auto;
      }
      @include respond-down(S){
        background-position: 3% center;
        background-size: auto 120%;
      }
    }
    .wines-header {
      .spot {
        display: none;
      }
    }
  }

  &.group-zimnyaya-skazka {
    .bg-helper {
      background: url('/static/images/header/zimnyaya-skazka.bg.svg') no-repeat center center;
      background-size: auto 90%;

      @include respond-down(L){
        background-size: auto 86%;
      }
      @include respond-down(M){
        background-size: auto 90%;
        background-position: left center;
      }
      @include respond-down(S){
        background-size: 270%;
        background-position: 0% 110%;
      }
    }

    .wines-header {
      transform: scale(1.5) translate3d(0, 0, 100px);
      z-index: 10;

      @include respond-down(XL){
        transform: scale(1.2) translate3d(0, 0, 100px);
      }
      @include respond-down(M){
        transform: none;
      }

      .spot {
        display: none;
      }
    }
  }

  &.group-osenniy-lis {
    .bg-helper {
      background: url('/static/images/header/osenniy-lis.bg.svg') no-repeat left center;
      background-size: 110%;

      @include respond-down(S){
        background-size: 250%;
      }
    }

    .wines-header {
      width: 250px;
      margin-left: -125px;

      .spot {
        & > g {
          transform: translate(-40px, -60px) rotate(5deg);
        }
        path {
          fill: #FF6A00;
        }
      }
    }

  }
}
